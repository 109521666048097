import { useEffect, useState } from "react";
import PetCard from "../../components/PetCard";
import Loading from "../../components/Loading";
import FilterSearchInput from "../../components/FilterSearchInput";
import useDebounce from "../../config/useDebounce";
import useFetchPets from "../../hooks/useFetchPets";
import UseCurrentLocation from "../../components/UseCurrentLocation";
import { debounce } from "../../config/utils";
import { useAuth } from "../../hooks/AuthContext";
import useAddress from "../../hooks/useAddress";
import LabeledAddressInput from "../../components/LabeledAddressInput";
import { createAddress, updateAddress } from "../../services/apiService";
import { useNavigate } from "react-router-dom";

const Pets = () => {
  const navigate = useNavigate();
  const [breed, setBreed] = useState("");
  const [petType, setPetType] = useState("All pets");
  const [page, setPage] = useState(1); // page state
  const { user } = useAuth();
  const address = useAddress(user?.uid);
  const [cityState, setCityState] = useState("");

  const [location, setLocation] = useState(
    window.localStorage.getItem("LOCATION") || ""
  );

  const debouncedBreed = useDebounce(breed, 500);
  const debouncedPetType = useDebounce(petType, 500);

  const { petsList, isLoading, error, fetchData } = useFetchPets(
    location,
    debouncedBreed,
    debouncedPetType,
    page.toString()
  );

  useEffect(() => {
    if (address) {
      setLocation(`${address.latitude},${address.longitude}`);
      setCityState(`${address.city}, ${address.state}`);
    }
  }, [address]);

  useEffect(() => {
    setPage(1); // Reset to page 1 for new filters
    fetchData("1"); // Pass "1" to force fetch on page 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, debouncedBreed, debouncedPetType]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Infinite scrolling logic
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
      if (isAtBottom && !isLoading) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [isLoading]);

  const [isEditingLocation, setIsEditingLocation] = useState(false);

  return (
    <div className="bg-primary min-h-screen flex flex-col items-center">
      <div className="mt-4 mb-4 w-full px-6 max-w-[850px]">
        <FilterSearchInput
          placeholder="Search by breed"
          search={breed}
          setSearch={setBreed}
          petType={petType}
          setPetType={setPetType}
          onPressSearch={() => {}}
        />

        {!location ? (
          <div className="pt-3">
            <UseCurrentLocation
              onLocationFound={(l) => {
                setLocation(l);
              }}
            />
          </div>
        ) : !isEditingLocation && cityState ? (
          <div className="flex flex-row space-x-4 pt-4">
            <p>{cityState}</p>
            <p
              onClick={() => {
                setIsEditingLocation(true);
              }}
              className="text-secondary-400 underline"
            >
              Edit
            </p>
          </div>
        ) : (
          user && (
            <div className="pt-4">
              <LabeledAddressInput
                label="Adjust your location"
                value={{
                  label: "",
                }}
                onChange={(v) => {
                  if (v) {
                    if (!address) {
                      createAddress({ ...v, auth_user_id: user?.uid });
                    } else {
                      updateAddress({ ...v, auth_user_id: user?.uid });
                    }
                    setIsEditingLocation(false);
                    setLocation(`${v.latitude},${v.longitude}`);
                    setCityState(`${v.city}, ${v.state}`);
                  }
                }}
                placeholder=""
              />
            </div>
          )
        )}
      </div>

      <div className="flex justify-center w-full">
        <div className="w-full lg:max-w-[1000px] flex flex-wrap justify-center">
          {petsList.map((pet, i) => {
            const formattedName = pet.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const petImageUrl =
              pet.photos.find((p) => p.medium)?.medium ||
              pet.primary_photo_cropped?.medium;
            return (
              <PetCard
                key={i}
                pet={pet}
                imageUrl={petImageUrl}
                name={formattedName}
                breed={pet.breeds.primary}
                age={pet.age}
                distance={pet.distance}
              />
            );
          })}

          {petsList.length === 0 && !isLoading && location && (
            <div className="text-center py-4 px-4">
              No results found in your area 😔
              <br />
              Try checking back later!
            </div>
          )}

          {isLoading && <Loading height={150} />}

          {error && (
            <div className="flex flex-col px-4 justify-center items-center">
              <div className="text-center text-error py-6 px-4">{error}</div>
              {error?.includes("sign up now") && (
                <button
                  type="submit"
                  onClick={() => {
                    navigate(`/create-account`);
                  }}
                  className="bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg max-w-[180px]"
                >
                  Sign Up
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pets;
