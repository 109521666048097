import { Pressable, StyleSheet, View } from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";
import { useEffect, useState } from "react";
import { Organization } from "../../types/types";
import { timeAgo } from "../../config/formatter";
import { fetchOrganizationById } from "../../services/apiService";

type Props = {
  was_viewed: boolean;
  createdAt: string;
  updatedAt: string;
  organization_id: string;
};
const FosterApplicationCard = ({
  was_viewed,
  createdAt,
  updatedAt,
  organization_id,
  ...rest
}: Props) => {
  const [organization, setOrganization] = useState<Organization>();

  useEffect(() => {
    const getOrganization = async () => {
      const orgRes = await fetchOrganizationById({ organization_id });
      if (orgRes.organization) {
        setOrganization(orgRes.organization);
      }
    };
    getOrganization();
  }, [organization_id]);

  return (
    <Pressable style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
        <View style={styles.avatar}>
          <Label styles={{ color: "white" }} sz="small" weight="medium">
            {organization?.name?.charAt(0)}
          </Label>
        </View>
        <View style={{ flex: 1 }}>
          <Label
            weight="medium"
            styles={{
              color: "black",
            }}
            numberOfLines={2}
            sz="small"
          >
            {organization?.name || "The rescue"}{" "}
            <Label weight="light" sz="small">
              {was_viewed ? "has viewed" : "has received"} your application and{" "}
              {was_viewed ? "should contact you soon" : "will review it soon"}.
            </Label>
          </Label>
          <Label styles={{ paddingVertical: 4 }} weight="light" sz="xsmall">
            {was_viewed ? timeAgo(updatedAt) : timeAgo(createdAt)}
          </Label>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    marginVertical: 8,
    padding: 12,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.border,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.colors.secondary[200],
    borderRadius: 18,
    width: 36,
    height: 36,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default FosterApplicationCard;
