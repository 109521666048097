import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import he from "he";
import { Animal, PetFinderOrganization } from "../../types/types";
import { logAnalyticsEvent } from "../../services/firebaseService";
import {
  createApplication,
  fetchAddress,
  fetchDistanceInMiles,
} from "../../services/apiService";
import {
  fetchPetfinderAnimal,
  fetchPetFinderAnimals,
  fetchPetfinderOrganization,
} from "../../services/petfinderService";
import SuccessModal from "../../components/SuccessModal";
import PetCard from "../../components/PetCard";
import ConfirmationModal from "../../components/ConfirmationModal";
import LabeledInput from "../../components/LabeledInput";
import { useAuth } from "../../hooks/AuthContext";
import useFoster from "../../hooks/useFoster";
import Loading from "../../components/Loading";

const PetDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [animal, setAnimal] = useState<Animal | null>(state?.animal);
  const { user } = useAuth();
  const foster = useFoster();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [didSendApplication, setDidSendApplication] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [petImageUrl, setPetImageUrl] = useState(
    animal?.photos?.find((p) => p.full)?.full
  );
  const [petFinderOrganization, setPetFinderOrganization] =
    useState<PetFinderOrganization | null>(null);
  const [petsList, setPetsList] = useState<Animal[]>([]); // List of other animals

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPetFinderOrg = async () => {
      const res = await fetchPetfinderOrganization({
        id: animal?.organization_id || "",
      });
      if (res.organization) {
        setPetFinderOrganization(res.organization);
      }
    };

    const fetchAnimal = async (id: string) => {
      const res = await fetchPetfinderAnimal({ id });
      if (res.animal) {
        setAnimal(res.animal);
      }
    };

    if (!animal && id) {
      fetchAnimal(id);
    } else if (animal && !animal.is_rescue_groups) {
      fetchPetFinderOrg();
    }
  }, [animal, id]);

  // Fetch other animals from the organization
  useEffect(() => {
    const fetchOtherAnimals = async () => {
      const res = await fetchPetFinderAnimals({
        location: window.localStorage.getItem("LAT_LNG") || "",
        organization: petFinderOrganization?.id,
        page: "1",
        limit: "10",
      });
      if (res?.animals) {
        setPetsList(res.animals);
      }
    };
    if (
      !petsList.length &&
      petFinderOrganization &&
      !window.localStorage.getItem("IS_RESCUE")
    ) {
      fetchOtherAnimals();
    }
  }, [petFinderOrganization, petsList]);

  const onSubmitApplication = async () => {
    if (user && foster && animal) {
      setIsLoading(true);
      setError("");
      logAnalyticsEvent({
        eventName: "submit_application",
        data: { screen: "pet" },
      });

      const userId = user?.uid;
      const addressRes = await fetchAddress({ auth_user_id: userId });
      if (
        addressRes.address?.latitude ||
        window.localStorage.getItem("LAT_LNG")
      ) {
        const userAddress = addressRes.address?.city
          ? `${addressRes.address?.latitude},${addressRes.address?.longitude}`
          : window.localStorage.getItem("LAT_LNG") || "";
        const animalAddress = animal.contact?.address?.city
          ? `${animal.contact.address.city}, ${animal.contact.address.state}`
          : `${petFinderOrganization?.address.city}, ${petFinderOrganization?.address.state}`;
        const distanceRes = await fetchDistanceInMiles(
          userAddress,
          animalAddress
        );
        if (distanceRes > 150) {
          setIsConfirmationVisible(true);
          return;
        }
      } else {
        window.alert(
          "Sorry, there was an issue on our end. Please reach out to our support team or try again later."
        );
        return;
      }

      if (!animal?.is_rescue_groups) {
        // Petfinder Organization
        const res = await createApplication({
          foster_id: foster.id,
          organization_email:
            animal.contact?.email ||
            petFinderOrganization?.email ||
            "achall@toplev.io",
          organization_name: petFinderOrganization?.name || "Your Rescue",
          message: message,
          petfinder_id: petFinderOrganization?.id,
          pet_name: animal?.name,
        });
        setDidSendApplication(res.success);
        setMessage("");
        if (res.error) {
          setError(
            "Sorry, there was an issue on our end. Please reach out to our support team or try again later."
          );
        }
      } else {
        // Non Petfinder organization
        const res = await createApplication({
          foster_id: foster.id,
          organization_email:
            animal.contact?.email ||
            petFinderOrganization?.email ||
            "achall@toplev.io",
          organization_name: animal?.contact?.org_name || "Your Rescue",
          pet_name: animal?.name,
          message: message,
          rescuegroup_id: animal?.organization_id,
        });
        setDidSendApplication(res.success);
        setMessage("");
        if (res.error) {
          setError(
            "Sorry, there was an issue on our end. Please reach out to our support team."
          );
        }
      }

      setIsLoading(false);
    } else {
      logAnalyticsEvent({
        eventName: "submit_application_attempt",
        data: { screen: "pet" },
      });
      if (id) {
        window.localStorage.setItem("SUBMITTING_PET_ID", id);
      }
      navigate("/create-account");
    }
  };

  const formattedName = animal?.name
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

  return !animal ? (
    <Loading />
  ) : (
    <div className="bg-secondary-100 p-4 w-full">
      <button onClick={() => navigate(-1)} className="mb-4">
        <FaArrowLeft size={28} />
      </button>

      <div className="flex flex-col items-center max-w-5xl mx-auto">
        <img
          className="rounded-lg w-full max-w-lg object-cover h-80"
          src={petImageUrl || require("../../assets/images/avatar.png")}
          alt="Pet"
        />

        <div className="flex space-x-2 mt-4 overflow-x-auto w-full max-w-lg">
          {animal.photos.map((photo, i) => (
            <button key={i} onClick={() => setPetImageUrl(photo.full)}>
              <img
                className={`rounded-lg w-32 h-24 object-cover ${
                  photo.full === petImageUrl
                    ? "border-2 border-secondary-400"
                    : ""
                }`}
                src={photo.full}
                alt={`Thumbnail ${i + 1}`}
              />
            </button>
          ))}
        </div>

        <div className="bg-white p-4 rounded-lg w-full max-w-lg mt-4">
          <h2 className="text-3xl font-semibold">{formattedName}</h2>
          <p className="text-lg text-secondary-400 mt-2">
            {animal.species === "Dog"
              ? animal.breeds.primary
              : `${animal.species} | ${animal.breeds.primary}`}
          </p>
          <p className="text-lg mt-2">
            {animal.distance ? `${animal.distance.toFixed(1)} mi away • ` : ""}
            {animal.contact.address.city}
          </p>
          <div className="border-t border-tertiary mt-4 mb-4"></div>
          <p className="text-lg">
            {animal.age} • {animal.gender} • {animal.size}
            {animal.coat ? ` • ${animal.coat}` : ""}
          </p>
          <p className="text-lg mt-2">
            {animal?.is_rescue_groups
              ? `${he.decode(animal.description || "")} Contact ${
                  petFinderOrganization?.name || "us"
                } for more information on ${animal.name}.`
              : animal.description
              ? `${he.decode(animal.description.split(".")[0])}. Contact ${
                  petFinderOrganization?.name || "us"
                } for more information on ${animal.name}.`
              : `Contact ${
                  petFinderOrganization?.name || "us"
                } for more information on ${animal.name}.`}
          </p>
        </div>

        {/* Application Form */}
        {!window.localStorage.getItem("IS_RESCUE") && (
          <div className="bg-secondary-400 text-white p-4 rounded-lg w-full max-w-lg mt-8 text-center">
            <h3 className="text-xl font-semibold">
              Want to help {formattedName}?
            </h3>
            <p className="text-lg mt-2">
              Apply now if you're interested to foster, pet sit, donate, or help{" "}
              {formattedName} in any way!
            </p>
            <LabeledInput
              label=""
              value={message}
              onChange={setMessage}
              placeholder="Send a message (optional)"
              multiline
            />
            {error && <p className="text-error mt-2">{error}</p>}
            <button
              onClick={onSubmitApplication}
              disabled={isLoading}
              className={`w-full py-2 mt-4 rounded-lg font-semibold text-lg ${
                isLoading
                  ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                  : "bg-white text-secondary-400 hover:scale-105 transition-transform"
              }`}
            >
              SUBMIT INTEREST
            </button>
          </div>
        )}

        {/* Organization Card */}
        {!!petFinderOrganization &&
          !window.localStorage.getItem("IS_RESCUE") && (
            <div className="bg-white border border-tertiary rounded-lg p-4 mt-8 w-full max-w-lg flex flex-col items-center text-center">
              <button
                onClick={() =>
                  navigate("/organization-detail", {
                    state: { organization: petFinderOrganization },
                  })
                }
              >
                <img
                  className="rounded-full w-24 h-24 border border-secondary-400 mb-4"
                  src={
                    petFinderOrganization?.photos.find((p) => p.medium)
                      ?.medium || "/assets/default-org.png"
                  }
                  alt="Organization"
                />
              </button>
              <h3 className="text-2xl font-semibold">
                {petFinderOrganization?.name}
              </h3>
              <p className="text-lg text-secondary-400 mt-2">
                {petFinderOrganization?.address.city},{" "}
                {petFinderOrganization?.address.state}
              </p>
            </div>
          )}

        {/* List of Other Animals */}
        {petsList.length > 0 && (
          <div className="mt-8 w-full">
            <h3 className="text-2xl font-semibold text-center">
              Other animals at this rescue
            </h3>
            <div className="flex flex-wrap gap-4 justify-center mt-4">
              {petsList.map((pet, i) => {
                const petNameFormatted = pet.name
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ");
                const petImageUrl =
                  pet.photos.find((p) => p.medium)?.medium ||
                  pet.primary_photo_cropped?.medium;
                return (
                  <PetCard
                    key={i}
                    pet={pet}
                    imageUrl={petImageUrl}
                    name={petNameFormatted}
                    breed={pet.breeds.primary}
                    age={pet.age}
                    distance={pet.distance}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>

      <SuccessModal
        isVisible={didSendApplication}
        onDismiss={() => setDidSendApplication(false)}
      />
      <ConfirmationModal
        isVisible={isConfirmationVisible}
        title="You look a bit far 🤔"
        message="You appear to be more than 150 miles away from this rescue. If this is incorrect, please enable location permissions or update your address on your application."
        cancelBtn="Dismiss"
        onDismiss={() => setIsConfirmationVisible(false)}
      />
    </div>
  );
};

export default PetDetail;
