import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  fetchAddress,
  fetchApplicationsForFoster,
  fetchFoster,
  updateApplication,
} from "../../services/apiService";
import { Address, Foster } from "../../types/types";
import PersonalInformation from "../../components/ApplicationForm/PersonalInformation";
import Loading from "../../components/Loading";
import FosterPreferences from "../../components/ApplicationForm/FosterPreferences";
import HomeInformation from "../../components/ApplicationForm/HomeInformation";
import { logAnalyticsEvent } from "../../services/firebaseService";
import { useAuth } from "../../hooks/AuthContext";
import useOrganization from "../../hooks/useOrganization";

const FosterApplication = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const organizationId = params.get("organization") || undefined;
  const { user } = useAuth();
  const organization = useOrganization({ organizationId: organizationId });

  const [application, setApplication] = useState<Foster | undefined>(undefined);
  const [fosterAddress, setFosterAddress] = useState<Address | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const getApplication = useCallback(async () => {
    if (!id) return;

    logAnalyticsEvent({ eventName: "foster_application_viewed" });
    setIsLoading(true);

    try {
      const [res, addressRes] = await Promise.all([
        fetchFoster({ auth_user_id: id }),
        fetchAddress({ auth_user_id: id }),
      ]);

      if (res.foster) {
        setApplication(res.foster);
      }
      if (addressRes.address) {
        setFosterAddress(addressRes.address);
      }
    } catch (err) {
      console.error("Error fetching application or address:", err);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getApplication();
  }, [getApplication]);

  useEffect(() => {
    const updateApplicationViewed = async () => {
      if (!application) {
        return;
      }
      const appsRes = await fetchApplicationsForFoster({
        foster_id: application.id,
      });

      const app = appsRes.applications?.find(
        (a) => String(a.organization_id) === organizationId
      );
      if (app && !app.was_viewed) {
        await updateApplication({ application_id: app.id, was_viewed: true });
      }
    };
    updateApplicationViewed();
  }, [application, organizationId]);

  return isLoading ? (
    <Loading height={400} />
  ) : (
    <div className="flex flex-col w-full bg-secondary-100 p-6 lg:p-12 justify-center items-center">
      {!organization?.auth_user_id && (
        <div className="flex flex-col bg-white rounded-lg p-6 w-full max-w-xl justify-center items-center">
          <h1 className="text-3xl font-semibold pb-2 text-center">
            Need More Fosters Fast?
          </h1>

          {/* <div className="max-w-sm sm:max-w-md md:max-w-sm lg:max-w-lg"> */}
          <div className="p-4 rounded-lg mb-6">
            <p className="text-md">
              Animal rescues using our platform have seen a{" "}
              <strong>30% increase</strong> in the number of animals they can
              rescue and rehome. Sign up to access our Foster Campaign tool and
              share urgent needs with our passionate network of animal lovers
              —whenever you need.
            </p>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            onClick={() => {
              navigate(
                `/create-rescue-account?email=${organization?.email}&name=${organization?.name}`
              );
            }}
            className="bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg w-full max-w-lg"
          >
            Get Started
          </button>
        </div>
      )}

      <form className="my-6 rounded-lg bg-white flex flex-col items-center w-full max-w-xl p-6">
        <h1 className="text-3xl font-bold ">Applicant Profile</h1>
        {!organization?.auth_user_id && (
          <p className="pb-6 pt-2 text-sm text-center text-secondary-400">
            This profile is saved so you can access it anytime from your
            Petfosterz Dashboard.
          </p>
        )}

        <div className="w-full">
          <PersonalInformation
            isEditable={false}
            userId={user?.uid || ""}
            application={application}
          />

          <div className="h-1 w-full bg-secondary-200 rounded my-4"></div>

          <FosterPreferences
            isEditable={false}
            fosterAddress={fosterAddress}
            userId={application?.auth_user_id || ""}
            application={application}
          />

          <div className="h-1 w-full bg-secondary-200 rounded my-4"></div>

          <HomeInformation
            isEditable={false}
            userId={application?.auth_user_id || ""}
            application={application}
          />
        </div>
      </form>
    </div>
  );
};

export default FosterApplication;
